import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: false,
  googleAdsEnabled: false,
  headerLiftEnabled: false,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@cnn',
  twitterSiteHandle: '@cnn',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '8203640d-7aa1-49a4-ac24-13faecd1c602',
  footerLogoId: 'e8288b79-7bcb-42fa-8ff3-82e4c6ff8d50',
  fontUrl: 'https://media-aws.byorbit.com/asset/4f59361f-1bd9-49bb-acd4-5229ba72de2c/',
  defaultTitle: 'Cnn',
  titleTemplate: 'or-site-cnn | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      id: 'trackmatricks',
      src: 'https://lightning.cnn.com/launch/7be62238e4c3/97fa00444124/launch-2878c87af5e3.min.js',
      strategy: 'none',
    },
    {
      id: 'userconsent',
      src: 'https://www.cnn.com/wmuc/cnn_user_consent.min.js',
      strategy: 'none',
    },
  ],
  links: [
  ],
};